
const  authProvider = {
    checkError: ({ error }) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        // console.log(localStorage.getItem('token'))
        return (localStorage.getItem('token'))
            ? true
            : false;//<Navigate to="/login" />;
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
    isFirstTime: () => {return true}
};

export default authProvider;