import * as React from 'react';
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import sysApi from './helpers/config';
import { useNavigate } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://testivr.com/">
        CronDock
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
const [showFailAlert, setShowFailAlert] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  
  const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
  
  const handleSubmit = (event) => {
    if (process.env.REACT_APP_TESTIVR_ENVIRONMENT === "PRODUCTION"){
      // window.gtag('event', 'conversion', {'send_to': 'AW-966333715/SqqSCIHTwsEDEJOq5MwD'});
    }
    event.preventDefault();
    const data = new FormData(event.currentTarget)
    if(!validateEmail(data.get('email'))){
      setMessage("Not a valid email address")
      setShowFailAlert(true);
    }else if (data.get('password') !== data.get('confirmpassword')){
      setMessage("Password and Confirm Password does not match")
      setShowFailAlert(true);
    }else{
      setShowFailAlert(false);
      fetch(`${sysApi.host}/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: data.get('email'),
          email: data.get('email'),
          password: data.get('password')
        })
      }).then(function (response) {
        if (response.status !== 200) {
            
          response.json().then(function (object) {
              var error_str = ""
              for (var key in object['error']) {
                  error_str = error_str + object['error'][key] + "\n"
              }
            setMessage(error_str)
            setShowFailAlert(true);
          });
        } else {
          response.json().then(function (object) {
            setShowFailAlert(false);
            localStorage.setItem('token', object.token)
            navigate('/')
          })
  
        }
  
      });  
    }
    
    
    
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          {
            showFailAlert && <div>
              <Alert severity="error">{message}</Alert>
            </div>
          }
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmpassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmpassword"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}