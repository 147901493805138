

import './App.css';
import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Home';

//<Route exact path='/register' element={<Register/>}/>
import SignIn from './SignIn';
import SignUp from './SignUp';
import PrivateRoute from './PrivateRoute';

const App = () => {
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<Home/>}/>
          </Route>
          <Route exact path='/login' element={<SignIn/>}/>
          <Route exact path='/signup' element={<SignUp/>}/>
        </Routes>
      </Fragment>
    </Router>
    
  );
}

export default App;
