import React from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import sysApi from '../helpers/config';

import Box from '@material-ui/core/Box';



class CheckoutForm extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements, updatePage} = this.props;

    if (elements == null) {
      return;
    }
    const appearance = {
      theme: 'night',
      labels: 'floating'
    };
    elements.update({appearance:appearance})
    stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })
      .then(function(result) {
          console.log("result", result)
          if (result.error!==undefined){
            alert(result.error.message)
            return
          }
        fetch(`${sysApi.host}/payment/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
              "payment_method_id": result.paymentMethod.id
            })
        }).then(function (response) {
            if (response.status == 200) {
                response.json().then(function (object) {
                  updatePage()
                })
            }else{
              alert("Your card was declined!")
              console.log(response.json())
            }
        })
      });
  };

  render() {
    const {stripe} = this.props;
    return (
      <Box p={3}>
      <Card
      
      >
      <CardContent>
      <form onSubmit={this.handleSubmit}>
      
        <CardElement/>
        <p/>
        <button type="submit" disabled={!stripe}>
          Submit
        </button>
      
      </form>
      </CardContent>
      </Card>
      </Box>
    );
  }
}

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm stripe={stripe} elements={elements} updatePage={props.updatePage}/>
    )}
  </ElementsConsumer>
);

const stripePromise = loadStripe(sysApi.stripe_key);

const CheckoutFormApp = (props) => {
    return (
  <Elements stripe={stripePromise}>
    <InjectedCheckoutForm 
    updatePage={props.updatePage}
    />
  </Elements>
)};

export default CheckoutFormApp;