import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import sysApi from '../helpers/config';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';


const tiers = [
  { 
    planType: 'free_plan',
    title: 'Free',
    price: '0',
    description: [
      'Maximum 2 Cron Jobs',
      'Maximum 10 Minutes Run Time Per Task',
      'Maximum 10 Task Runs Per Day',
      'Maximum Frequncy Of 1 Run Per Day for Each Cron Job',
      'Maximum 1 Cron Workflows',
      'Maximum 2 Tasks Per Workflows',
      'Maximum 3 Workflow Runs Per Day',
      'Maximum 10 Minutes Run Time Per Workflow',
      'Maximum Frequncy Of 1 Run Per Day for Each Wrokflow',
      'Private Container Repositories Not Supported',
      'Email Support'
    ],
    buttonText: 'Select',
  },
  {
    planType: 'basic_plan',
    title: 'Basic',
    subheader: 'Most popular',
    price: '20',
    description: [
      'Maximum 20 Cron Jobs',
      'Maximum 60 Minutes Run Time Per Task',
      'Maximum 100 Task Runs Per Day',
      'Maximum Frequncy Of 1 Run Per Hour for Each Cron Job',
      'Maximum 5 Cron Workflows',
      'Maximum 5 Tasks Per Workflows',
      'Maximum 10 Workflow Runs Per Day',
      'Maximum 60 Minutes Run Time Per Workflow',
      'Maximum Frequncy Of 4 Run Per Day for Each Wrokflow',
      'Maximum 2 Private Container Repositories',
      'Email Support'
    ],
    buttonText: 'Select',
  },
  {
    planType: 'enterprise_plan',
    title: 'Enterprise',
    price: '?',
    description: [
      'Custom Number of Cron Jobs',
      'Custom Run Time Per Task',
      'Custom Number of Runs Per Day',
      'Custom Maximum Frequncy for Each Cron Job',
      'Custom Number of Cron Workflows',
      'Custom Number of Tasks Per Workflows',
      'Custom Number of Workflow Runs Per Day',
      'custom Run Time Per Workflow',
      'Custom Maximum for Each Wrokflow',
      'Supporting Custom Number of Private Container Repositories',
      'Phone & Email Support'
    ],
    buttonText: 'Contact me',
  },
];


const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 300,
      maxWidth: 300,
      '& > * + *': {
        marginTop: 36,
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const PricingContent = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [planId, setPlanId] = useState("");
  const [headerConfirm, setHeaderConfirm] = useState("");
  const [textConfirm, setTextConfirm] = useState("");
  
  const handleCancel = () => {
    setOpenConfirm(false);
  }
  
  const handleConfirmed = () => {
    setOpenConfirm(false);
    handleChangePlan(planId)
  }
  
  const handleChangePlan = (plan_id) => {
    setShowFailAlert(false);
    if(plan_id==='enterprise_plan'){
       window.location.assign("mailto:support@crondock.com");
       return
    }else if (plan_id==='basic_plan'){
      if(props.payment==null){
        setMessage("Please add a credit card first.");
        setShowFailAlert(true);
      }else{
        setLoading(true);
        setShowFailAlert(false);
        fetch(`${sysApi.host}/subscription/`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${localStorage.getItem('token')}`
              },
              body: JSON.stringify({
                "price_id": sysApi.basic_plan_id
              })
          }).then(function (response) {
              setLoading(false);
              if (response.status == 200) {
                  response.json().then(function (object) {
                    console.log("subscribed: ", object)
                    props.updatePage();
                  })
              }else{
                response.json().then(function (object) {
                  console.log(object)
                  setMessage(object.data);
                  setShowFailAlert(true);
                })
                
                
              }
          })
      }
    }else if (plan_id==='free_plan'){
      setLoading(true);
      fetch(`${sysApi.host}/subscription/${props.subscription_id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        }).then(function (response) {
            setLoading(false);
            if (response.status == 200) {
                response.json().then(function (object) {
                  console.log("unsubscribe success: ", object)
                  props.updatePage();
                })
            }else{
                response.json().then(function (object) {
                  console.log(object)
                  setMessage(object.data);
                  setShowFailAlert(true);
                })
            }
        })
    }
    
  }
  
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      
      {/* Hero unit */}
      {loading &&
          <div className={classes.root}>
            <div className={classes.backdrop}>
                <Backdrop className={classes.backdrop} open={loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
      }
      <Dialog
          open={openConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{headerConfirm}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             {textConfirm}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmed} color="primary">
              Yes
            </Button>
            <Button onClick={handleCancel} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
      </Dialog>
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 1, pb: 1 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Plans
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 12}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={
                  tier.planType!=props.plan_type?'contained':'outlined'
                  }
                  disabled={(tier.planType==props.plan_type) || (tier.planType==='free_plan' && props.cancel_at_period_end)}
                  onClick={() => {
                    setHeaderConfirm("Are you sure you want to change your plan to " + tier.title +"?")
                    if(props.plan_type === 'free_plan'){
                      setTextConfirm("Note that your card will be charged $" + tier.price +" now and on a recurrent basis until you change back your plan to free.")
                    }else{
                      setTextConfirm("Note that your current plan will stay active until the end of this period and you can not change your plan again until then.")
                    }
                    
                    if(tier.planType==='enterprise_plan'){
                      handleChangePlan('enterprise_plan')
                    }else{
                      setPlanId(tier.planType);
                      setOpenConfirm(true);
                    }
                    
                  }}
                  >
                  
                    {tier.planType==props.plan_type?
                      (!props.cancel_at_period_end?
                        'Current Plan'
                        :
                        'Active until:' +new Date(props.current_period_end*1000).toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})
                      )
                      :
                      ((tier.planType==='free_plan' && props.cancel_at_period_end)?
                      'Current Plan'
                      :
                      tier.buttonText
                      )
                    }
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        
        {
            showFailAlert && <div>
              <Alert severity="error">{message}</Alert>
            </div>
          }
      </Container>
      
    </React.Fragment>
  );
}

export default PricingContent;