var sysApi = {
        host: 'https://88a3-34-234-54-130.ngrok.io',
        basic_plan_id: 'price_1LCYWSGc9vwe6ex0iNkqJCVB',
        stripe_key: 'pk_test_51LCYTMGc9vwe6ex0havdbN8XzGZYexmG7xynScWMAm4CCubLkrzwfdDd2rZUQqsMgS3AgYSfIo9yD8iYqQzXE7Mc009Fsd7ul4',
    }
if (process.env.REACT_APP_CRONDOCK_ENVIRONMENT === "PRODUCTION"){
    sysApi = {
        host: 'https://api.crondock.com',
        basic_plan_id: 'price_1LEgRKGc9vwe6ex0cWiQunYO',
        stripe_key: 'pk_live_51LCYTMGc9vwe6ex0a01SQ7JmkKn1a1ZGcGrGtjwstGiCj95M6ZRGtuaqG9oo9kmLAvYyQiAoLa92BrRRvaKAvw7f00cJS0Zjmy',
    }
}    



export default sysApi;